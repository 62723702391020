<template>
  <div class="main">
    <TopMenu
        v-bind:menuList="menuList"
        v-bind:currentPage="currentPage"
    />
    <router-view
        v-bind:bio="bio"
        v-bind:messages="messages"
        v-bind:info="info"
        v-bind:videoList="videoList"
        v-bind:langList="langList"
    />
    <Footer/>
  </div>
</template>

<script>
import TopMenu from './components/TopMenu.vue'
import Footer from './components/Footer.vue'

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');
if (locale === 'ru') {
  locale = locale ? '/' + locale : '';
} else {
  locale = '';
}

export default {
  name: 'App',
  data() {
    if (locale === '/ru') {
      return {
        langList: [
          {
            title: 'English',
            code: 'en',
            href: '/',
            titleCode: 'en',
          },
        ],
        currentPage: this.$router.currentRoute,
        bio: {
          name: 'Влад Бажанов',
          profession: 'Senior Backend Разработчик',
          workExperience: '9 лет',
          description: 'Не боюсь сложных задач. С удовольствием принимаю и делюсь профессиональными знаниями.',
          links: [
            {
              title: 'Telegram',
              icon: 'telegram',
              href: 'https://t.me/vlads'
            },
            {
              title: 'LinkedIn',
              icon: 'linkedin-square',
              href: 'https://linkedin.com/in/vlad-bazhanov'
            },
            {
              title: 'Instagram',
              icon: 'instagram',
              href: 'https://instagram.com/vlads.pro'
            },
          ]
        },
        messages: {
          'WorkExperience': 'Опыт работы',
          'Skills': 'Навыки',
          'Languages': 'Языки',
          'Education': 'Образование',
          'Videos': 'Видео',
        },
        menuList: [
          {id: 1, title: 'Главная', url: locale !== '' ? locale + '/' : '/', icon: 'vcard'},
          {id: 2, title: 'Информация', url: locale + '/info', icon: 'info'},
          {id: 3, title: 'Публичность', url: locale + '/pub', icon: 'newspaper-o'},
        ],
        info: {
          lang: [
            {
              'title': 'Русский (носитель)'
            },
            {
              'title': 'Английский (Intermediate)'
            },
          ],
          skills: [
            {
              title: 'PHP',
            },
            {
              title: 'JS',
            },
            {
              title: 'MySQL',
            },
            {
              title: 'PostgreSQL',
            },
            {
              title: 'Elasticsearch',
            },
            {
              title: 'Laravel',
            },
            {
              title: 'Python',
            },
            {
              title: 'PHPUnit',
            },
            {
              title: 'Kafka',
            },
            {
              title: 'RabbitMQ',
            },
            {
              title: 'Redis',
            },
            {
              title: 'S3',
            },
            {
              title: 'HTML',
            },
            {
              title: 'CSS',
            },
            {
              title: 'Vue.js',
            },
            {
              title: 'TypeScript',
            },
            {
              title: 'Linux',
            },
            {
              title: 'Docker',
            },
            {
              title: 'Kubernetes',
            },
            {
              title: 'Git',
            },
            {
              title: 'Gitlab CI/CD',
            },
            {
              title: 'ClickHouse',
            },
            {
              title: 'ELK',
            },
            {
              title: 'Bitrix Framework',
            },
          ],
          workHistory: [
            {
              title: 'PHP-Разработчик',
              short: 'Avito',
              dateFrom: 'Октябрь 2022',
              dateTo: 'Настоящее время',
              descList: [
                {id: 0, value: 'Разработка нового функционала, доработки существующего'},
                {id: 1, value: 'Участие в проработке архитектурных решений'},
                {id: 2, value: 'Code-review коллег'},
                {id: 3, value: 'Участие в построении работы команды разработки'},
              ],
              skills: [
                {
                  title: 'PHP',
                },
                {
                  title: 'JS',
                },
                {
                  title: 'MySQL',
                },
                {
                  title: 'PHPUnit',
                },
                {
                  title: 'Kafka',
                },
                {
                  title: 'ELK',
                },
                {
                  title: 'S3',
                },
                {
                  title: 'HTML',
                },
                {
                  title: 'CSS',
                },
                {
                  title: 'Bitrix Framework',
                },
              ],
            },
            {
              title: 'PHP-Разработчик',
              short: 'Bitrix24',
              dateFrom: 'Февраль 2019',
              dateTo: 'Сентябрь 2022',
              dateDuration: '3 года 7 месяцев',
              descList: [
                {
                  id: 0,
                  value: 'Доработки модуля Rest API (оптимизации производительности, добавление нового функционала, поддержка старого, с сохранением обратной совместимости)'
                },
                {id: 1, value: 'Поддержка и доработки высоконагруженного OAuth'},
                {id: 2, value: 'Написание скриптов для сбора данных из ClickHouse'},
                {id: 3, value: 'Добавление новых rest-методов в разные части продукта'},
                {id: 4, value: 'Написан механизм no-code решений нового типа приложений'},
                {id: 5, value: 'Выступления на конференциях'},
                {id: 6, value: 'Code-review коллег'},
              ],
              skills: [
                {
                  title: 'PHP',
                },
                {
                  title: 'JS',
                },
                {
                  title: 'MySQL',
                },
                {
                  title: 'Bitrix Framework',
                },
                {
                  title: 'Rest API',
                },
                {
                  title: 'Python',
                },
                {
                  title: 'ClickHouse',
                },
                {
                  title: 'PHPUnit',
                },
                {
                  title: 'RabbitMQ',
                },
                {
                  title: 'S3',
                },
                {
                  title: 'HTML',
                },
                {
                  title: 'CSS',
                },
              ],
            },
            {
              title: 'PHP-Разработчик',
              short: 'Фриланс',
              dateFrom: 'Декабрь 2015',
              dateTo: 'Февраль 2019',
              dateDuration: '3 года 2 месяцев',
              descList: [
                {id: 0, value: 'Создание сайтов-визиток, интернет магазинов, личных кабинетов клиентов'},
                {id: 1, value: 'Написание интеграций с внешними системами'},
                {id: 2, value: 'Автоматизация процессов'},
                {id: 3, value: 'Интеграция с Bitrix24 по Rest API'},
                {
                  id: 4,
                  value: 'Генерация docx документов с данными из базы данных на основе заготовленных шаблонов документов с якорями'
                }
              ],
              skills: [
                {
                  title: 'Bitrix Framework',
                },
                {
                  title: 'PHP',
                },
                {
                  title: 'JS',
                },
                {
                  title: 'MySQL',
                },
              ],
            },
            {
              title: 'Технический специалист',
              short: 'Томский государственный университет · по совместительству',
              dateFrom: 'Август 2015',
              dateTo: 'Февраль 2019',
              dateDuration: '3 года 6 месяцев',
              descList: [
                {id: 0, value: 'Настройка оборудования корпуса'},
                {id: 1, value: 'Техническое сопровождение конференций'},
                {id: 2, value: 'Консультирование'},
              ],
            },
          ],
          educationHistory: [
            {
              title: 'Физико-технический факультет · бакалавриат',
              dateFrom: 'Сентябрь 2013',
              dateTo: 'Март 2018',
              dateDuration: 'не окончен',
              short: 'Томский государственный университет ',
            },
            {
              title: 'PHP Developer. Professional',
              dateFrom: 'Октябрь 2022',
              dateTo: 'Апрель 2023',
              dateDuration: '5 месяцев',
              short: 'OTUS',
              descList: [
                {id: 0, value: 'Внутреннее устройство PHP'},
                {id: 1, value: 'Composer'},
                {id: 2, value: 'Сети, протоколы, балансировка, безопасность'},
                {id: 1, value: 'Базы данных (Алгоритмы, PostgreSQL, Redis, MySQL, PHP и базы данных)'},
                {id: 1, value: 'Архитектура кода'},
                {id: 1, value: 'Практики хорошего кода'},
                {id: 1, value: 'Design patterns'},
                {id: 1, value: 'Unit-тестирование'},
                {id: 1, value: 'Профилирование, логирование и мониторинг'},
                {id: 1, value: 'Очереди'},
                {id: 1, value: 'Проектирование API'},
              ],
            },
            {
              title: 'Базы данных',
              dateFrom: 'Август 2023',
              dateTo: 'Апрель 2024',
              dateDuration: '6 месяцев',
              short: 'OTUS',
              descList: [
                {id: 0, value: 'Полный курс по работе с реляционными и нереляционными базами данных'},
                {
                  id: 1,
                  value: 'Сущности и связи (Проектирование БД, Компоненты современной СУБД, Проблемы миграции, Теорема CAP, проектирование)'
                },
                {id: 2, value: 'PostgreSQL'},
                {id: 2, value: 'MySQL'},
                {id: 2, value: 'NoSQL (Redis, Tarantool, MongoDB, Cassandra, Clickhouse)'},
              ],
            },
          ],
          /*  ikigai: {
              'love': 'you'
            },*/
        },
        videoList: [
          {
            'title': 'Bitrix Talks TECH. Разбор механизма событий REST.',
            'date': '2022-04-28',
            'youtubeCode': '3WSH9kJl-4c',
          },
          {
            'title': 'Разработка REST-приложений. Полезное.',
            'date': '2021-06-30',
            'youtubeCode': 'UiGgSn7_igg',
          },
          {
            'title': 'Архитектура rest-приложений для высоких нагрузок.',
            'date': '2020-04-09',
            'youtubeCode': 'lgdoJJpRFDI',
          },
          {
            'title': 'Как создавать отраслевые CRM в Битрикс24 без разработки?',
            'date': '2020-02-14',
            'youtubeCode': '50jUqm45jek',
          },
        ],
      }
    }

    return {
      langList: [
        {
          title: 'Русский',
          code: 'ru',
          href: '/ru/',
          titleCode: 'ru',
        },
      ],
      currentPage: this.$router.currentRoute,
      bio: {
        name: 'Vlad Bazhanov',
        profession: 'Senior Backend Developer',
        workExperience: '9 years',
        //description: 'Не боюсь сложных задач. С удовольствием принимаю и делюсь профессиональными знаниями.',
        links: [
          {
            title: 'Telegram',
            icon: 'telegram',
            href: 'https://t.me/vlads'
          },
          {
            title: 'LinkedIn',
            icon: 'linkedin-square',
            href: 'https://linkedin.com/in/vlad-bazhanov/'
          },
          {
            title: 'Instagram',
            icon: 'instagram',
            href: 'https://instagram.com/vlads.pro'
          },
        ]
      },
      messages: {
        'WorkExperience': 'Working experience',
        'Skills': 'Skills',
        'Languages': 'Languages',
        'Education': 'Education',
        'Videos': 'Videos',
      },
      menuList: [
        {id: 1, title: 'Home', url: locale !== '' ? locale + '/' : '/', icon: 'vcard'},
        {id: 2, title: 'Information', url: locale + '/info', icon: 'info'},
        {id: 3, title: 'Publication', url: locale + '/pub', icon: 'newspaper-o'},
      ],
      info: {
        lang: [
          {
            'title': 'Russian (native)'
          },
          {
            'title': 'English (Intermediate)'
          },
        ],
        skills: [
          {
            title: 'PHP',
          },
          {
            title: 'JS',
          },
          {
            title: 'MySQL',
          },
          {
            title: 'PostgreSQL',
          },
          {
            title: 'Elasticsearch',
          },
          {
            title: 'Laravel',
          },
          {
            title: 'Python',
          },
          {
            title: 'PHPUnit',
          },
          {
            title: 'Kafka',
          },
          {
            title: 'RabbitMQ',
          },
          {
            title: 'Redis',
          },
          {
            title: 'S3',
          },
          {
            title: 'HTML',
          },
          {
            title: 'CSS',
          },
          {
            title: 'Vue.js',
          },
          {
            title: 'TypeScript',
          },
          {
            title: 'Linux',
          },
          {
            title: 'Docker',
          },
          {
            title: 'Kubernetes',
          },
          {
            title: 'Git',
          },
          {
            title: 'Gitlab CI/CD',
          },
          {
            title: 'ClickHouse',
          },
          {
            title: 'ELK',
          },
          {
            title: 'Bitrix Framework',
          },
        ],
        workHistory: [
          {
            title: 'PHP Developer',
            short: 'Avito · Full-time',
            dateFrom: 'Oct 2022',
            dateTo: 'Present',
            descList: [
              {id: 0, value: 'Development of a new functionality, refinement of the existing one'},
              {id: 1, value: 'Participation in working on solutions architecture'},
              {id: 2, value: 'Colleagues’ code-review'},
              {id: 3, value: 'Participation in creation of technical regulations, CI/CD, etc of dev-team’s work'},
            ],
            skills: [
              {
                title: 'PHP',
              },
              {
                title: 'JS',
              },
              {
                title: 'MySQL',
              },
              {
                title: 'PHPUnit',
              },
              {
                title: 'Kafka',
              },
              {
                title: 'ELK',
              },
              {
                title: 'S3',
              },
              {
                title: 'HTML',
              },
              {
                title: 'CSS',
              },
              {
                title: 'Bitrix Framework',
              },
            ],
          },
          {
            title: 'PHP Developer',
            short: 'Bitrix24 · Full-time',
            dateFrom: 'Feb 2019',
            dateTo: 'Sep 2022',
            dateDuration: '3 years 7 months',
            descList: [
              {
                id: 0,
                value: 'Refinement of Rest-API module (Performance optimization, adding of a new functionality, support of existing one. All of them are with saving of backward compatibility)'
              },
              {id: 1, value: 'Support and refinement of highload OAuth servers'},
              {id: 2, value: 'Scripting for gathering analytic data from ClickHouse'},
              {id: 3, value: 'Inserting of new rest-methods in different product modules'},
              {id: 4, value: 'Development of a new no-code mechanism for external integration in the product'},
              {id: 5, value: 'Presentations at conferences'},
              {id: 6, value: 'Colleagues’ code-review'},
            ],
            skills: [
              {
                title: 'PHP',
              },
              {
                title: 'JS',
              },
              {
                title: 'MySQL',
              },
              {
                title: 'Bitrix Framework',
              },
              {
                title: 'Rest API',
              },
              {
                title: 'Python',
              },
              {
                title: 'ClickHouse',
              },
              {
                title: 'PHPUnit',
              },
              {
                title: 'RabbitMQ',
              },
              {
                title: 'S3',
              },
              {
                title: 'HTML',
              },
              {
                title: 'CSS',
              },
            ],
          },
          {
            title: 'PHP Developer',
            short: 'Freelance worker · Freelance',
            dateFrom: 'Dec 2015',
            dateTo: 'Feb 2019',
            dateDuration: '3 years 2 months',
            descList: [
              {id: 0, value: 'Creation landings, e-commerce sites, clients’ personal online cabinets'},
              {id: 1, value: 'Development of integrations with external systems'},
              {id: 2, value: 'Integration with Bitrix24 by Rest API'},
              {id: 3, value: 'Automatization of business processes'},
              {
                id: 4,
                value: 'Development of a docs generator using data from DB by prepared templates with anchor tags'
              }
            ],
            skills: [
              {
                title: 'Bitrix Framework',
              },
              {
                title: 'PHP',
              },
              {
                title: 'JS',
              },
              {
                title: 'MySQL',
              },
            ],
          },
          {
            title: 'Tomsk State University',
            short: 'Technical Specialist · Part-time',
            dateFrom: 'Aug 2015',
            dateTo: 'Feb 2019',
            dateDuration: '3 years 6 months',
            descList: [
              {id: 0, value: 'Setup and support equipment of faculty'},
              {id: 1, value: 'Technical support of conferences taking place in the University'},
            ],
          },
        ],
        educationHistory: [
          {
            title: 'Physics and Technology Faculty',
            dateFrom: 'Sep 2013',
            dateTo: 'Mar 2018',
            dateDuration: 'not finished',
            short: 'National Research Tomsk State University',
          },
          {
            title: 'PHP Developer. Professional',
            dateFrom: 'Oct 2022',
            dateTo: 'Apr 2023',
            dateDuration: '5 months',
            short: 'OTUS',
            descList: [
              {id: 0, value: 'Internal structure PHP'},
              {id: 1, value: 'Composer'},
              {id: 2, value: 'Network, protocol, balance load, security'},
              {id: 1, value: 'Databases (Algorithms, PostgreSQL, Redis, MySQL, PHP and Databases)'},
              {id: 1, value: 'Code architecture'},
              {id: 1, value: 'Best practices'},
              {id: 1, value: 'Design patterns'},
              {id: 1, value: 'Unit testing'},
              {id: 1, value: 'Profiling, logging and monitoring'},
              {id: 1, value: 'Message brokers'},
              {id: 1, value: 'Designing APIs'},
            ],
          },
          {
            title: 'Databases',
            dateFrom: 'Aug 2023',
            dateTo: 'Apr 2024',
            dateDuration: '6 months',
            short: 'OTUS',
            descList: [
              {id: 0, value: 'Full course on working with relational and non-relational databases'},
              {
                id: 1,
                value: 'Entities and relationships (DB designing, modern DBMS, Migration problems, CAP theory, engineering)'
              },
              {id: 2, value: 'PostgreSQL'},
              {id: 2, value: 'MySQL'},
              {id: 2, value: 'NoSQL (Redis, Tarantool, MongoDB, Cassandra, Clickhouse)'},
            ],
          },
        ],
        /*  ikigai: {
            'love': 'you'
          },*/
      },
      videoList: [
        {
          'title': 'Bitrix Talks TECH. Understanding the REST Event Mechanism.',
          'date': '2022-04-28',
          'youtubeCode': '3WSH9kJl-4c',
        },
        {
          'title': 'Development of REST applications. Useful.',
          'date': '2021-06-30',
          'youtubeCode': 'UiGgSn7_igg',
        },
        {
          'title': 'How to develop apps to cloud. High load architecture for Rest API apps.',
          'date': '2020-04-09',
          'youtubeCode': 'lgdoJJpRFDI',
        },
        {
          'title': 'How to create vertical CRM in Bitrix24 without development?',
          'date': '2020-02-14',
          'youtubeCode': '50jUqm45jek',
        },
      ],
    }
  },
  components: {
    TopMenu,
    Footer,
  },
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,300italic,400italic");
@import 'assets/css/font-awesome.min.css';
@import 'assets/css/style.css';
</style>
