<template>
  <div class="footer">
    <span class="author nocopy">by <a href="https://t.me/vlads" target="_blank">@Vlads</a></span>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {
  },
  props: {
    langList: {},
  }
}
</script>
<style scoped>
.footer {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.author {
  color: rgba(255, 255, 255, 0.45);
  line-height: 30px;
}
a {
  color: rgba(255, 255, 255, 0.45);
}

a:hover {
  color: #fff;
}
</style>