import {createRouter, createWebHistory} from 'vue-router';

import Home from '@/views/Home';

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');
if (locale === 'ru') {
    locale = locale ? '/' + locale : '';
} else {
    locale = '';
}
export default new createRouter({
    history: createWebHistory(),
    base: (locale.trim().length && locale !== "/") ? '/' + locale : undefined,
    routes: [
        {
            path: locale + '/',
            component: Home,
        },
        {
            path: locale + '/info',
            component: () => import('./views/Info.vue'),
        },
        {
            path: locale + '/pub',
            component: () => import('./views/Public.vue'),
        },
        {
            path: '/:catchAll(.*)',
            component: () => import('./views/NotFound.vue'),
        },
    ]
})
